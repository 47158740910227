/* eslint-disable camelcase */
/* eslint-disable no-mixed-spaces-and-tabs */
import { TextEncoder } from './encoding.js'

function createNew () {
  const jpPrinter = {}
  let data = ''
  const command = []
  jpPrinter.name = '蓝牙打印机'
  // 清除图像缓冲区（image buffer)的数据
  jpPrinter.setCls = () => {
    jpPrinter.addCommand('CLS\r\n')
  }
  // 设定卷标纸的宽度和长度
  jpPrinter.setSize = (pageWidght, pageHeight) => {
    data =
      'SIZE ' +
      pageWidght.toString() +
      ' mm' +
      ',' +
      pageHeight.toString() +
      ' mm' +
      '\r\n'
    jpPrinter.addCommand(data)
  }
  // 两张卷标纸间的垂直间距距离
  jpPrinter.setGap = printGap => {
    data = 'GAP ' + printGap.toString() + ' mm,0 mm\r\n'
    jpPrinter.addCommand(data)
  }
  /**
   * 该指令用于在标签上画线
   * 传入参数说明
   * x 线条左上角X 坐标，单位dots
   * y 线条左上角Y 坐标，单位dots
   * width  线宽，单位dots
   * height 线高，单位dots
   */
  jpPrinter.setBar = function (x, y, width, height) {
    // 绘制线条
    data = 'BAR ' + x + ',' + y + ',' + width + ',' + height + '\r\n'
    jpPrinter.addCommand(data)
  }
  /**
   * 该指令用于定义卷标的参考坐标原点。坐标原点位置和打印方向有关
   * 传入参数说明
   * x 水平方向的坐标位置,单位dots
   * y 垂直方向的坐标位置,单位dots
   * 打印机分辨率200 DPI:  1 mm = 8  dots
   * 打印机分辨率300 DPI:  1 mm = 12 dots
   */
  jpPrinter.setReference = function (x, y) {
    data = 'REFERENCE ' + x + ',' + y + '\r\n'
    jpPrinter.addCommand(data)
  }
  /**
   * 该指令用于在卷标上绘制矩形方框
   * x_start 方框左上角X 坐标，单位dots
   * y_start 方框左上角Y 坐标，单位dots
   * x_end 方框右下角X 坐标，单位dots
   * y_end 方框右下角Y 坐标，单位dots
   * thickness 方框线宽，单位dots
   */
  jpPrinter.setBox = function (x_start, y_start, x_end, y_end, thickness) {
    data =
      'BOX ' +
      x_start +
      ',' +
      y_start +
      ',' +
      x_end +
      ',' +
      y_end +
      ',' +
      thickness +
      '\r\n'
    jpPrinter.addCommand(data)
  }

  /**
   * 该指令用于控制打印机进一张标签纸
   */
  jpPrinter.setFromfeed = function () {
    data = 'FORMFEED\r\n'
    jpPrinter.addCommand(data)
  }

  /**
   * 在使用含有间隙或黑标的标签纸时，若不能确定第一张标签纸是否在正确打印位
   * 置时，此指令可将标签纸向前推送至下一张标签纸的起点开始打印。标签尺寸和
   * 间隙需要在本条指令前设置
   * 注：使用该指令时，纸张高度大于或等于30 mm
   */
  jpPrinter.setHome = function () {
    // 根据Size找到下一张标签纸的位置
    data = 'HOME\r\n'
    jpPrinter.addCommand(data)
  }

  /**
   * 该指令用于打印出存储于影像缓冲区内的数据
   * 传入参数说明  打印份数
   * 1≤n≤65535
   */
  jpPrinter.setPrint = function (n) {
    data = 'PRINT ' + n + ',1\r\n'
    jpPrinter.addCommand(data)
  }
  /**
   * 该指令用于打印字符串
   * 传入参数说明
   * x 文字X 方向起始点坐标
   *  y 文字Y 方向起始点坐标
   *  font 字体名称
   *  1 8×12 dot 英数字体
   *  2 12×20 dot 英数字体
   *  3 16×24 dot 英数字体
   *  4 24×32 dot 英数字体
   *  5 32×48 dot 英数字体
   *  6 14×19 dot 英数字体OCR-B
   *  7 21×27 dot 英数字体OCR-B
   *  8 14×25 dot 英数字体OCR-A
   *  9 9×17 dot 英数字体
   *  10 12×24 dot 英数字体
   *  TSS16.BF2 简体中文16×16（GB 码）
   *  TSS20.BF2 简体中文20×20（GB 码）
   *  TST24.BF2 繁体中文24×24（大五码）
   *  TSS24.BF2 简体中文24×24（GB 码）
   *  K 韩文24×24Font（KS 码）
   * TSS32.BF2 简体中文32×32（GB 码）
   * rotation 文字旋转角度（顺时针方向） 0， 90， 180， 270
   */
  jpPrinter.setText = (x, y, font, rotation, x_, y_, str) => {
    data =
      'TEXT ' +
      x +
      ',' +
      y +
      ',"' +
      font +
      '",' +
      rotation +
      ',' +
      x_ +
      ',' +
      y_ +
      ',' +
      '"' +
      str +
      '"\r\n'
    jpPrinter.addCommand(data)
  }
  /**
   * 该指令用来画一维条码
   * 传入参数说明
   * x 左上角水平坐标起点，以点（dot）表示
   * y 左上角垂直坐标起点，以点（dot）表示
   * codetype 打印模式 128
   * height 条形码高度，以点（dot）表示
   * readable 0 表示人眼不可识，1 表示人眼可识
   * rotation 条形码旋转角度，顺时针方向 0,90,180,270
   * narrow 窄bar 宽度，以点（dots）表示
   * wide 宽bar 宽度，以点（dot）表示
   * content 打印内容
   */
  jpPrinter.setBarCode = (
    x,
    y,
    codetype,
    height,
    readable,
    rotation,
    narrow,
    wide,
    content
  ) => {
    data =
      'BARCODE ' +
      x +
      ',' +
      y +
      ',"' +
      codetype +
      '",' +
      height +
      ',' +
      readable +
      ',' +
      rotation +
      ',' +
      narrow +
      ',' +
      wide +
      ',"' +
      content +
      '"\r\n'
    jpPrinter.addCommand(data)
  }
  /**
   * 该指令用来打印二维码
   * ｘ 二维码水平方向起始点坐标
   * ｙ 二维码垂直方向起始点坐标
   * ECC level 选择QRCODE 纠错等级
   *   L 7%
   *   M 15%
   *   Q 25%
   *   H 30%
   * cell width 二维码宽度1-10
   * mode 手动/自动编码
   *   A Auto
   *   M Manual
   * rotation 旋转角度（顺时针方向） 0，90，180，270
   * content  内容
   */
  jpPrinter.setQrcode = (x, y, level, width, mode, content) => {
    data =
      'QRCODE ' +
      x +
      ',' +
      y +
      ',' +
      level +
      ',' +
      width +
      ',' +
      mode +
      ',' +
      0 +
      ',"' +
      content +
      '"\r\n'
    jpPrinter.addCommand(data)
  }
  // 将指令转成数组装起
  jpPrinter.addCommand = content => {
    var code = new TextEncoder('gb18030', {
      NONSTANDARD_allowLegacyEncoding: true
    }).encode(content)
    for (var i = 0; i < code.length; ++i) {
      command.push(code[i])
    }
  }
  // 打印出存储于影像缓冲区内的数据
  jpPrinter.setPagePrint = () => {
    jpPrinter.addCommand('PRINT 1,1\r\n')
  }
  // 获取打印数据
  jpPrinter.getData = () => {
    return command
  }
  return jpPrinter
}

export { createNew }
