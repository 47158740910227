/* eslint-disable camelcase */
import { arr } from './gb18030.js'

var floor = Math.floor
var isASCIICodePoint = isASCIIByte
var encoders = {}
var decoders = {}
var label_to_encoding = {}
var encodings = [
  {
    encodings: [
      {
        labels: ['gb18030'],
        name: 'gb18030'
      }
    ],
    heading: 'Legacy multi-byte Chinese (simplified) encodings'
  }
]
const global = {
  'encoding-indexes': {
    gb18030: arr
  }
}
function inRange (a, min, max) {
  return min <= a && a <= max
}
function includes (array, item) {
  return array.indexOf(item) !== -1
}
function ToDictionary (o) {
  if (o === undefined) return {}
  if (o === Object(o)) return o
}
function stringToCodePoints (string) {
  var s = String(string)
  var n = s.length
  var i = 0
  var u = []
  while (i < n) {
    var c = s.charCodeAt(i)
    if (c < 0xd800 || c > 0xdfff) {
      u.push(c)
    } else if (c >= 0xdc00 && c <= 0xdfff) {
      u.push(0xfffd)
    } else if (c >= 0xd800 && c <= 0xdbff) {
      if (i === n - 1) {
        u.push(0xfffd)
      } else {
        var d = s.charCodeAt(i + 1)
        if (d >= 0xdc00 && d <= 0xdfff) {
          var a = c & 0x3ff
          var b = d & 0x3ff
          u.push(0x10000 + (a << 10) + b)
          i += 1
        } else {
          u.push(0xfffd)
        }
      }
    }
    i += 1
  }
  return u
}
function codePointsToString (code_points) {
  var s = ''
  for (var i = 0; i < code_points.length; ++i) {
    var cp = code_points[i]
    if (cp <= 0xffff) {
      s += String.fromCharCode(cp)
    } else {
      cp -= 0x10000
      s += String.fromCharCode((cp >> 10) + 0xd800, (cp & 0x3ff) + 0xdc00)
    }
  }
  return s
}
function isASCIIByte (a) {
  return a >= 0x00 && a <= 0x7f
}
function Stream (tokens) {
  this.tokens = [].slice.call(tokens)
  this.tokens.reverse()
}
Stream.prototype = {
  endOfStream () {
    return !this.tokens.length
  },
  read () {
    if (!this.tokens.length) return -1
    return this.tokens.pop()
  },
  prepend (token) {
    if (Array.isArray(token)) {
      var tokens = token
      while (tokens.length) {
        this.tokens.push(tokens.pop())
      }
    } else {
      this.tokens.push(token)
    }
  },
  push (token) {
    if (Array.isArray(token)) {
      var tokens = token
      while (tokens.length) {
        this.tokens.unshift(tokens.shift())
      }
    } else {
      this.tokens.unshift(token)
    }
  }
}
function decoderError (fatal, opt_code_point) {
  if (fatal) throw TypeError('Decoder error')
  return opt_code_point || 0xfffd
}
function Decoder () {}
Decoder.prototype = {
  handler: function (stream, bite) {}
}
function Encoder () {}
Encoder.prototype = {
  handler: function (stream, code_point) {}
}
function getEncoding (label) {
  label = String(label)
    .trim()
    .toLowerCase()
  if (Object.prototype.hasOwnProperty.call(label_to_encoding, label)) { return label_to_encoding[label] }
  return null
}
encodings.forEach(category => {
  category.encodings.forEach(encoding => {
    encoding.labels.forEach(label => {
      label_to_encoding[label] = encoding
    })
  })
})
function indexCodePointFor (pointer, index) {
  if (!index) return null
  return index[pointer] || null
}

function indexPointerFor (code_point, index) {
  var pointer = index.indexOf(code_point)
  return pointer === -1 ? null : pointer
}
function indexGB18030RangesCodePointFor (pointer) {
  if ((pointer > 39419 && pointer < 189000) || pointer > 1237575) {
    return null
  }
  if (pointer === 7457) return 0xe7c7
  var offset = 0
  var code_point_offset = 0
  var idx = arr
  var i
  for (i = 0; i < idx.length; ++i) {
    var entry = idx[i]
    if (entry[0] <= pointer) {
      offset = entry[0]
      code_point_offset = entry[1]
    } else {
      break
    }
  }
  return code_point_offset + pointer - offset
}
function indexGB18030RangesPointerFor (code_point) {
  if (code_point === 0xe7c7) return 7457
  var offset = 0
  var pointer_offset = 0
  var idx = arr
  var i
  for (i = 0; i < idx.length; ++i) {
    var entry = idx[i]
    if (entry[1] <= code_point) {
      offset = entry[1]
      pointer_offset = entry[0]
    } else {
      break
    }
  }
  return pointer_offset + code_point - offset
}
function TextDecoder (label, options) {
  if (!(this instanceof TextDecoder)) { throw TypeError("Called as a function. Did you forget 'new'?") }
  label = label !== undefined ? String(label) : 'utf-8'
  options = ToDictionary(options)
  this._encoding = null
  this._decoder = null
  this._ignoreBOM = false
  this._BOMseen = false
  this._error_mode = 'replacement'
  this._do_not_flush = false
  var encoding = getEncoding(label)
  if (encoding === null || encoding.name === 'replacement') { throw RangeError('Unknown encoding: ' + label) }

  if (!decoders[encoding.name]) {
    throw Error(
      'Decoder not present.' +
        ' Did you forget to include encoding-indexes.js first?'
    )
  }
  var dec = this
  dec._encoding = encoding
  if (options.fatal) dec._error_mode = 'fatal'
  if (options.ignoreBOM) dec._ignoreBOM = true
  if (!Object.defineProperty) {
    this.encoding = dec._encoding.name.toLowerCase()
    this.fatal = dec._error_mode === 'fatal'
    this.ignoreBOM = dec._ignoreBOM
  }
  return dec
}
if (Object.defineProperty) {
  Object.defineProperty(TextDecoder.prototype, 'encoding', {
    get: function () {
      return this._encoding.name.toLowerCase()
    }
  })
  Object.defineProperty(TextDecoder.prototype, 'fatal', {
    get: function () {
      return this._error_mode === 'fatal'
    }
  })
  Object.defineProperty(TextDecoder.prototype, 'ignoreBOM', {
    get: function () {
      return this._ignoreBOM
    }
  })
}
TextDecoder.prototype.decode = function decode (input, options) {
  var bytes
  if (typeof input === 'object' && input instanceof ArrayBuffer) {
    bytes = new Uint8Array(input)
  } else if (
    typeof input === 'object' &&
    'buffer' in input &&
    input.buffer instanceof ArrayBuffer
  ) {
    bytes = new Uint8Array(input.buffer, input.byteOffset, input.byteLength)
  } else {
    bytes = new Uint8Array(0)
  }
  options = ToDictionary(options)
  if (!this._do_not_flush) {
    this._decoder = decoders[this._encoding.name]({
      fatal: this._error_mode === 'fatal'
    })
    this._BOMseen = false
  }
  this._do_not_flush = Boolean(options.stream)
  var input_stream = new Stream(bytes)
  var output = []
  var result
  while (true) {
    var token = input_stream.read()
    if (token === -1) {
      break
    }
    result = this._decoder.handler(input_stream, token)
    if (result === -1) {
      break
    }
    if (result !== null) {
      if (Array.isArray(result)) {
        output.push.apply(output, result)
      } else {
        output.push(result)
      }
    }
  }
  if (!this._do_not_flush) {
    do {
      result = this._decoder.handler(input_stream, input_stream.read())
      if (result === -1) {
        break
      }
      if (result === null) {
        continue
      }
      if (Array.isArray(result)) {
        output.push.apply(output, result)
      } else {
        output.push(result)
      }
    } while (!input_stream.endOfStream())
    this._decoder = null
  }
  function serializeStream (stream) {
    if (
      includes(['UTF-8', 'UTF-16LE', 'UTF-16BE'], this._encoding.name) &&
      !this._ignoreBOM &&
      !this._BOMseen
    ) {
      if (stream.length > 0 && stream[0] === 0xfeff) {
        this._BOMseen = true
        stream.shift()
      } else if (stream.length > 0) {
        this._BOMseen = true
      } else {
      }
    }
    return codePointsToString(stream)
  }
  return serializeStream.call(this, output)
}
function TextEncoder (label, options) {
  if (!(this instanceof TextEncoder)) { throw TypeError("Called as a function. Did you forget 'new'?") }
  options = ToDictionary(options)
  this._encoding = null
  this._encoder = null
  this._do_not_flush = false
  this._fatal = options.fatal ? 'fatal' : 'replacement'
  var enc = this
  if (options.NONSTANDARD_allowLegacyEncoding) {
    label = label !== undefined ? String(label) : 'utf-8'
    var encoding = getEncoding(label)
    if (encoding === null || encoding.name === 'replacement') {
      throw RangeError('Unknown encoding: ' + label)
    }
    if (!encoders[encoding.name]) {
      throw Error(
        'Encoder not present.' +
          ' Did you forget to include encoding-indexes.js first?'
      )
    }
    enc._encoding = encoding
  } else {
    enc._encoding = getEncoding('utf-8')
    if (label !== undefined && 'console' in global) {
      console.warn(
        'TextEncoder constructor called with encoding label, ' +
          'which is ignored.'
      )
    }
  }
  if (!Object.defineProperty) {
    this.encoding = enc._encoding.name.toLowerCase()
  }
  return enc
}
if (Object.defineProperty) {
  Object.defineProperty(TextEncoder.prototype, 'encoding', {
    get: function () {
      return this._encoding.name.toLowerCase()
    }
  })
}
TextEncoder.prototype.encode = function encode (opt_string, options) {
  opt_string = opt_string === undefined ? '' : String(opt_string)
  options = ToDictionary(options)
  if (!this._do_not_flush) {
    this._encoder = encoders[this._encoding.name]({
      fatal: this._fatal === 'fatal'
    })
  }
  this._do_not_flush = Boolean(options.stream)
  var input = new Stream(stringToCodePoints(opt_string))
  var output = []
  var result
  while (true) {
    var token = input.read()
    if (token === -1) {
      break
    }
    result = this._encoder.handler(input, token)
    if (result === -1) {
      break
    }
    if (Array.isArray(result)) {
      output.push.apply(output, result)
    } else {
      output.push(result)
    }
  }
  if (!this._do_not_flush) {
    while (true) {
      result = this._encoder.handler(input, input.read())
      if (result === -1) {
        break
      }
      if (Array.isArray(result)) {
        output.push.apply(output, result)
      } else {
        output.push(result)
      }
    }
    this._encoder = null
  }
  return new Uint8Array(output)
}
function SingleByteDecoder (index, options) {
  var fatal = options.fatal
  this.handler = function (stream, bite) {
    if (bite === -1) return -1
    if (isASCIIByte(bite)) return bite
    var code_point = index[bite - 0x80]
    if (code_point === null) return decoderError(fatal)
    return code_point
  }
}
function SingleByteEncoder (index, options) {
  // var fatal = options.fatal
  this.handler = function (stream, code_point) {
    if (code_point === -1) return -1
    if (isASCIICodePoint(code_point)) return code_point
    var pointer = indexPointerFor(code_point, index)
    if (pointer === null) return pointer + 0x80
  }
}
(function () {
  if (!('encoding-indexes' in global)) return
  encodings.forEach(category => {
    if (category.heading !== 'Legacy single-byte encodings') return
    category.encodings.forEach(encoding => {
      var name = encoding.name
      var idx = arr
      decoders[name] = options => new SingleByteDecoder(idx, options)
      encoders[name] = options => new SingleByteEncoder(idx, options)
    })
  })
})()
function GB18030Decoder (options) {
  var fatal = options.fatal
  var gb18030_first = 0x00
  var gb18030_second = 0x00
  var gb18030_third = 0x00
  this.handler = (stream, bite) => {
    if (
      bite === -1 &&
      gb18030_first === 0x00 &&
      gb18030_second === 0x00 &&
      gb18030_third === 0x00
    ) { return -1 }
    if (
      bite === -1 &&
      (gb18030_first !== 0x00 ||
        gb18030_second !== 0x00 ||
        gb18030_third !== 0x00)
    ) {
      gb18030_first = 0x00
      gb18030_second = 0x00
      gb18030_third = 0x00
      decoderError(fatal)
    }
    var code_point
    if (gb18030_third !== 0x00) {
      code_point = null
      if (inRange(bite, 0x30, 0x39)) {
        code_point = indexGB18030RangesCodePointFor(
          (((gb18030_first - 0x81) * 10 + gb18030_second - 0x30) * 126 +
            gb18030_third -
            0x81) *
            10 +
            bite -
            0x30
        )
      }
      var buffer = [gb18030_second, gb18030_third, bite]
      gb18030_first = 0x00
      gb18030_second = 0x00
      gb18030_third = 0x00
      if (code_point === null) {
        stream.prepend(buffer)
        return decoderError(fatal)
      }
      return code_point
    }
    if (gb18030_second !== 0x00) {
      if (inRange(bite, 0x81, 0xfe)) {
        gb18030_third = bite
        return null
      }
      stream.prepend([gb18030_second, bite])
      gb18030_first = 0x00
      gb18030_second = 0x00
      return decoderError(fatal)
    }
    if (gb18030_first !== 0x00) {
      if (inRange(bite, 0x30, 0x39)) {
        gb18030_second = bite
        return null
      }
      var lead = gb18030_first
      var pointer = null
      gb18030_first = 0x00
      var offset = bite < 0x7f ? 0x40 : 0x41
      if (inRange(bite, 0x40, 0x7e) || inRange(bite, 0x80, 0xfe)) { pointer = (lead - 0x81) * 190 + (bite - offset) }
      code_point = pointer === null ? null : indexCodePointFor(pointer, arr)
      if (code_point === null && isASCIIByte(bite)) stream.prepend(bite)
      if (code_point === null) return decoderError(fatal)
      return code_point
    }
    if (isASCIIByte(bite)) return bite
    if (bite === 0x80) return 0x20ac
    if (inRange(bite, 0x81, 0xfe)) {
      gb18030_first = bite
      return null
    }
    return decoderError(fatal)
  }
}
function GB18030Encoder (options, gbk_flag) {
  // var fatal = options.fatal
  this.handler = (stream, code_point) => {
    if (code_point === -1) return -1
    if (isASCIICodePoint(code_point)) return code_point
    if (code_point === 0xe5e5) return
    if (gbk_flag && code_point === 0x20ac) return 0x80
    var pointer = indexPointerFor(code_point, arr)
    if (pointer !== null) {
      var lead = floor(pointer / 190) + 0x81
      var trail = pointer % 190
      var offset = trail < 0x3f ? 0x40 : 0x41
      return [lead, trail + offset]
    }
    if (gbk_flag) return
    pointer = indexGB18030RangesPointerFor(code_point)
    var byte1 = floor(pointer / 10 / 126 / 10)
    pointer = pointer - byte1 * 10 * 126 * 10
    var byte2 = floor(pointer / 10 / 126)
    pointer = pointer - byte2 * 10 * 126
    var byte3 = floor(pointer / 10)
    var byte4 = pointer - byte3 * 10
    return [byte1 + 0x81, byte2 + 0x30, byte3 + 0x81, byte4 + 0x30]
  }
}
encoders.gb18030 = options => new GB18030Encoder(options)
decoders.gb18030 = options => new GB18030Decoder(options)
export { TextEncoder }
